export const NAMESPACE = 'ramblerIdHelper'
export const SENTRY_NAMESPACE = `ramblerIdHelperSentry`
export const TEST_NAMESPACE = 'ramblerIdHelperTest'

export const RAMBLER_SLD_MATCH = /^https?:\/\/[^/]+\.rambler\.ru\/?/

export const IS_REDIRECT_AUTH_PENDING_KEY =
  'rambler-id-redirect-auth-is-pending'

export const SENTRY_DSN =
  'https://f99ca5e803f817657b00dbfa13623161@sentry-saas.rambler-co.ru/147'

export const SENTRY_IGNORE_ID_ERRORS = [
  -11,
  -4000,
  -2000,
  -2002,
  -3000,
  'need session',
  'invalid login',
  'rate limit exceed',
  'api does not respond',
  'location does not respond'
]

export const SENTRY_IGNORE_ERRORS = [
  // common network errors
  /AbortError/,
  /NetworkError/,
  /Failed to fetch/,
  /Resource blocked/,
  // some ad errors
  /webkit.messageHandlers/
]

export const SENTRY_IGNORE_URLS = [
  /file:\/\/\//,
  /webcache/,
  /translate/,
  /proxy/,
  /www\.youtube\.com/,
  /plugin\.ucads\.ucweb\.com/
]

export const SENTRY_THRESHOLD = 1
